import { PageHeader, Card, Row, Col } from "antd";
import React from "react";

const { Meta } = Card;

function callback(key) {
  console.log(key);
}

const gingeImage = "assets/ginge.png";
const yamboImage = "assets/yambo.png";

const gingeTwitterUrl = "https://www.twitter.com/ginge_eth";
const yamboTwitterUrl = "https://www.twitter.com/yambo_eth";

const cardMargin = "0px 10px";

// shows a display of the team
export default function Team() {
  return (
    <div style={{ marginTop: 40 }}>
      <h1 style={{ fontSize: 42 }}>Caretakers</h1>
      <div style={{ marginTop: 30 }}></div>
      <h6
        style={{
          width: "70%",
          textAlign: "center",
          fontWeight: "normal",
          margin: "auto auto 40px auto",
          fontSize: 18,
          maxWidth: 1200,
        }}
      >
        The Will3 Team consists of builders and product managers with over 10 years of collective experience in Web3.
        Their product-focused roles include time at Apple, TD Ameritrade, Paramount Studios, game developers, YC
        startups, Netflix and other tech companies.
      </h6>
      <Card
        hoverable
        onClick={() => {
          window.open(gingeTwitterUrl);
        }}
        style={{ width: "30%", maxWidth: 170, display: "inline-block", margin: cardMargin, textAlign: "center" }}
        cover={<img alt="example" src={gingeImage} />}
      >
        <Meta title="ginge.eth" description="@ginge_eth" />
      </Card>
      <Card
        hoverable
        onClick={() => {
          window.open(yamboTwitterUrl);
        }}
        style={{ width: "30%", maxWidth: 170, display: "inline-block", margin: cardMargin, textAlign: "center" }}
        cover={<img alt="example" src={yamboImage} />}
      >
        <Meta title="yambo.eth" description="@yambo_eth" />
      </Card>
    </div>
  );
}
