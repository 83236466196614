import { Steps, Card, Row, Col, Button, Tooltip, Spin } from "antd";
import React, { useEffect, useState, useContext, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { LoadingOutlined, InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
const { ethers } = require("ethers");
import mixpanel from "mixpanel-browser";
import { MIXPANEL_API_KEY, MIXPANEL_DEBUG } from "../constants";
import ApproveToken from "./CreateWill3/ApproveToken";
mixpanel.init(MIXPANEL_API_KEY, { debug: MIXPANEL_DEBUG, ignore_dnt: true });

export default function TokenApprovalRow(props) {
  const { token, blockExplorer, signer, provider, address, setTokens, index } = props;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  /*
  console.log("token object");
  console.log(token);
  */

  return (
    <tr style={{ height: "60px", width: "100%" }} key={index}>
      <td>
        <a
          style={{ textDecoration: "underline" }}
          href={`${blockExplorer}token/${token.contract_address}`}
          target="_blank"
        >
          {token.contract_name} {token.contract_decimals === 0 ? `#${token.token_id}` : ``}
        </a>
      </td>
      <td>{token.contract_ticker_symbol}</td>
      <td>{token.contract_decimals === 0 ? 1 : Number(ethers.utils.formatEther(token.balance)).toFixed(4)}</td>
      <td style={{ borderRight: 0 }}>
        {token.isApproved === true ? (
          <Tooltip
            placement="top"
            title="This token is approved for a potential future disbursal by Will3 smart contracts"
          >
            {token.contract_decimals === 0 ? (
              "✅"
            ) : (
              <a
                onClick={() => {
                  ApproveToken(signer, provider, address, token, blockExplorer);
                  mixpanel.track("Clicked Re-Approve Token Button", {
                    page: "create",
                    asset: token.contract_address,
                    assetSymbol: token.contract_ticker_symbol,
                    assetName: token.contract_name,
                  });
                }}
                style={{ color: "black", textDecoration: "underline" }}
              >
                {token.percentageApproved}%
              </a>
            )}
          </Tooltip>
        ) : token.isApproved === false ? (
          <Button
            shape="round"
            size="medium"
            className="connect-wallet-button"
            onClick={async () => {
              token.isApproved = null;
              // setTokens(tokens);
              const result = await ApproveToken(signer, provider, address, token, blockExplorer);
              console.log("This is the result");
              console.log(result);
              mixpanel.track("Clicked Approve Token Button", {
                page: "create",
                asset: token.contract_address,
                assetSymbol: token.contract_ticker_symbol,
                assetName: token.contract_name,
              });
            }}
          >
            Approve
          </Button>
        ) : (
          <Fragment>
            <Spin indicator={antIcon} />
          </Fragment>
        )}
      </td>
    </tr>
  );
}
