import React, { useEffect, useState, useContext, Fragment, useRef } from "react";
import { LoadingOutlined, InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { TokenAddressListContext } from "../context/TokenAddressList";
import { Table } from "react-bootstrap";
const { ethers } = require("ethers");
import { Button, Spin, Tooltip } from "antd";
import ApproveToken from "./CreateWill3/ApproveToken";
import useWindowSize from "./hooks/useWindowHook";
import { Link } from "react-router-dom";
import { COVALENT_API_KEY, erc20Abi, erc721Abi, MIXPANEL_API_KEY, MIXPANEL_DEBUG } from "../constants";
import ApproveAllTokens from "./CreateWill3/ApproveAllTokens";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import TokenApprovalRow from "./TokenApprovalRow";
import TokenApprovalRowEmpty from "./TokenApprovalRowEmpty";

mixpanel.init(MIXPANEL_API_KEY, { debug: MIXPANEL_DEBUG, ignore_dnt: true });

const appId = "9ggJYZ1Zq1CDZtREIWKZRWxD9FDA5BccA0DMi0sJ";
// const serverUrl = "https://whhchiqcehly.usemoralis.com:2053/server";

export default function ApproveUtil(props) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  // Moralis.start({ serverUrl, appId });

  const { chainId, userAddress, signer, provider, address, blockExplorer } = props;

  const { updateTokenList } = useContext(TokenAddressListContext);

  const [tokens, setTokens] = useState([]);
  const [nativeBalance, setnativeBalance] = useState(null);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [error, setError] = useState("");
  const componentMounted = useRef(true);
  const size = useWindowSize();

  const makeCall = async (callName, contract, args, metadata = {}) => {
    if (contract[callName]) {
      let result;
      if (args) {
        result = await contract[callName](...args, metadata);
      } else {
        result = await contract[callName]();
      }
      return result;
    }
    return undefined;
    console.log("no call of that name!");
  };

  const [loadingTokens, setLoadingTokens] = useState(false);

  useEffect(async () => {
    let tokens;
    let isSubscribed = true;

    console.log(componentMounted.current);

    const fetchTokens = async () => {
      await (async () => {
        try {
          //  https://api.covalenthq.com/v1/1/address/demo.eth/balances_v2/?key=ckey_8b8f75df462c418a847c1b3607c
          console.log("covalent url");
          const lookupUrl = `https://api.covalenthq.com/v1/${chainId}/address/${userAddress}/balances_v2/?format=JSON&nft=true&no-nft-fetch=true&key=${COVALENT_API_KEY}`;
          console.log(lookupUrl);
          await axios.get(lookupUrl).then(response => {
            tokens = response.data.data.items;
            setnativeBalance(ethers.utils.formatEther(tokens[0].balance));
            tokens.splice(0, 1);
            console.log(tokens);
            setTokens(tokens);
            updateTokenList(tokens);
          });
          /*
          const options = { chain: "0x4", address: `${userAddress}` };
          const balances = await Moralis.Web3API.account.getTokenBalances(options);
          const nativeBalance = await Moralis.Web3API.account.getNativeBalance(options);
          const nftTokens = await Moralis.Web3API.account.getNFTs(options);
          console.log("nft tokens");
          tokens = balances;
          nftTokens.result.forEach(el => {
            tokens.push(el);
          });
          */
          console.log(tokens);
          console.log("------------");

          //if (isSubscribed) {
          // setTokens(tokens);
          // updateTokenList(tokens);
          // }
        } catch (e) {
          if (e) {
            console.log(e);
            setError(e);
          }
        }
      })();

      const checkAllAllowances = async () => {
        if (tokens) {
          tokens.forEach(async token => {
            const checkWill3TokenAllowance = async () => {
              try {
                let tempContract;
                let result;
                // console.log(token);
                if (token.supports_erc.length > 0 && token.supports_erc[0] === "erc721") {
                  tempContract = new ethers.Contract(token.token_address, erc721Abi, signer);
                  result = await makeCall("isApprovedForAll", tempContract, [userAddress, address]);
                  console.log("result");
                  console.log(result);
                  token.isApproved = result;
                } else if (token.supports_erc.length > 0 && token.supports_erc[0] === "erc20") {
                  tempContract = new ethers.Contract(token.contract_address, erc20Abi, signer);
                  result = await makeCall("allowance", tempContract, [userAddress, address]);
                  console.log("result");
                  console.log(result);
                  if (result._hex !== "0x00") {
                    token.isApproved = true;
                    const approvedAmount = result.toString();
                    const tokenBalance = token.balance / Math.pow(10, token.contract_decimals);
                    const decimal = approvedAmount / tokenBalance;
                    const finalValue = decimal * 100;
                    token.percentageApproved = finalValue <= 100 ? finalValue.toString() : 100; // (decimal * 100).toString();
                  } else {
                    token.isApproved = false;
                  }
                } else {
                  token.isApproved = false;
                }

                /*
                console.log(token);
                console.log(address);
                console.log(userAddress);
                */
              } catch (e) {
                /*
                console.log(address);
                console.log(userAddress);
                */
                console.log(e);
              }
            };
            await checkWill3TokenAllowance();
          });
        }
      };

      await checkAllAllowances();
      setFinishedLoading(true);
    };

    await fetchTokens().catch(console.error);

    return () => (isSubscribed = false);
  }, [provider, address]);

  if (error.length) {
    <Fragment>
      <Spin indicator={antIcon} />
    </Fragment>;
  }

  if (!finishedLoading) {
    return (
      <Fragment>
        <Spin indicator={antIcon} />
      </Fragment>
    );
  } else
    return (
      <span>
        {nativeBalance == null ? (
          <Fragment>
            <Spin indicator={antIcon} />
          </Fragment>
        ) : (
          <Table>
            <thead>
              <tr>
                <th style={{ width: "30%" }}>Asset</th>
                <th style={{ width: "10%" }}>Symbol</th>
                <th style={{ width: "25%" }}>Balance</th>
                <th style={{ width: "35%", borderRight: 0 }}>
                  Approval
                  <Tooltip
                    placement="top"
                    title="Assets that will be disbursed through Will3 need to be approved by you."
                  >
                    <InfoCircleOutlined style={{ marginLeft: 6, fontSize: "16px", verticalAlign: "middle" }} />
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody>
              <Fragment>
                <tr style={{ height: "60px", width: "100%" }}>
                  <td>Ethereum</td>
                  <td>ETH</td>
                  <td>{Number(nativeBalance).toFixed(4)}</td>
                  <td style={{ borderRight: 0 }}>
                    <Tooltip placement="top" title="ETH must be converted to WETH to be approved and disbursed.">
                      <span
                        onClick={() => {
                          // console.log("convert to WETH here");
                        }}
                        style={{ textDecoration: "normal" }}
                      >
                        Convert to WETH
                      </span>
                      {/*<WarningOutlined style={{ fontSize: "16px" }} />*/}
                    </Tooltip>
                  </td>
                </tr>
              </Fragment>

              {tokens.map((token, index) => (
                <TokenApprovalRow
                  token={token}
                  blockExplorer={blockExplorer}
                  signer={signer}
                  provider={provider}
                  address={address}
                  setToken={setTokens}
                  index={index}
                />
              ))}
              {/*
              {tokens && tokens.filter(el => el.isApproved == false).length > 1 ? (
                <TokenApprovalRowEmpty
                  tokens={tokens}
                  blockExplorer={blockExplorer}
                  signer={signer}
                  provider={provider}
                  address={address}
                  setTokens={setTokens}
                  ApproveAllTokens={ApproveAllTokens}
                  index={tokens.length}
                />
              ) : (
                ``
              )}
              */}
            </tbody>
          </Table>
        )}
      </span>
    );
}
