import React, { useContext, useState } from "react";
import { ethers } from "ethers";
import { notification } from "antd";
import { erc20Abi, erc721Abi } from "../../constants";

export default function ApproveToken(signer, provider, address, token, blockExplorer) {
  const makeCall = async (callName, contract, args, metadata = {}) => {
    if (contract[callName]) {
      let result;
      if (args) {
        result = await contract[callName](...args, metadata);
      } else {
        result = await contract[callName]();
      }
      return result;
    }
    return undefined;
    console.log("no call of that name!");
  };

  const updateWill3TokenAllowance = async () => {
    try {
      let tempContract;
      let result;
      if (token.supports_erc.length > 0 && token.supports_erc[0] === "erc721") {
        tempContract = new ethers.Contract(token.contract_address, erc721Abi, signer);
        result = await makeCall("setApprovalForAll", tempContract, [address, true]);
      } else if (token.supports_erc.length > 0 && token.supports_erc[0] === "erc20") {
        tempContract = new ethers.Contract(token.contract_address, erc20Abi, signer);
        result = await makeCall("approve", tempContract, [
          address,
          ethers.utils.hexlify(ethers.utils.parseUnits("1000000000", token.contract_decimals)),
        ]);
      }

      console.log("here it is");
      console.log(result);
      if (result) {
        // turn checker back to false so it checks again?
      }
      console.log(signer);
      console.log(provider);
      notification.info({
        message: `Approving ${token.contract_ticker_symbol} with Will3`,
        description: "View transaction",
        placement: "bottomRight",
        onClick: () => {
          notification.destroy();
          window.open(`${blockExplorer}tx/${result.hash}`, "_blank");
        },
        duration: 10,
      });
      return true;
    } catch (e) {
      console.log(e);
    }
  };

  updateWill3TokenAllowance();
  return null;
}
