import { Steps, Card, Row, Col, Button, Tooltip, Spin } from "antd";
import React, { useEffect, useState, useContext, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { LoadingOutlined, InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
const { ethers } = require("ethers");
import mixpanel from "mixpanel-browser";
import { MIXPANEL_API_KEY, MIXPANEL_DEBUG } from "../constants";
mixpanel.init(MIXPANEL_API_KEY, { debug: MIXPANEL_DEBUG, ignore_dnt: true });

export default function TokenApprovalRowEmpty(props) {
  const { tokens, blockExplorer, signer, provider, address, ApproveAllTokens, index } = props;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <tr
      style={{
        height: "60px",
        width: "auto",
        backgroundColor: "transparent !important",
        margin: "auto",
        right: 0,
        borderBottom: 0,
      }}
      key={index}
    >
      <td className="empty-cell"></td>
      <td className="empty-cell"></td>
      <td className="empty-cell"> </td>
      <td className="approve-all-cell">
        <p className="approve-all-link">
          <a
            shape="round"
            size="medium"
            onClick={() => {
              ApproveAllTokens(signer, provider, address, tokens, blockExplorer);
              mixpanel.track("Clicked Approve All Tokens Button", {
                page: "create",
                asset: tokens.length,
              });
            }}
          >
            Approve All
          </a>
        </p>
      </td>
    </tr>
  );
}
